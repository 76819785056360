<!-- @format -->

<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content" :class="{ show: mqShallShowLeftSidebar }">
        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-form-input v-model="searchQuery" placeholder="Search..." @keyup.enter="confirmSearch" :disabled="load" />
              <BButton variant="outline-primary" pill v-if="searchQuery" @click="confirmSearch">Search</BButton>
            </b-input-group>
          </div>
        </div>

        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="chat-user-list-wrapper list-group scroll-area">
          <!-- 上级会员聊天信箱 -->
          <h4 class="chat-list-title">Sponsor</h4>
          <ul class="chat-users-list chat-list media-list" v-if="filteredChatsContacts.length">
            <chat-contact
              v-for="contact in filteredChatsContacts"
              :key="contact.id"
              :user="contact"
              tag="li"
              :class="{ active: activeChatContactId === contact.id }"
              is-chat-contact
              @click="$emit('open-chat', { contact, c: true })"
            />
          </ul>
          <ul v-else>
            <b-card style="margin: 20px auto" class="mb-2">
              <b-card-text> No more messages </b-card-text>
            </b-card>
          </ul>

          <!-- 下级会员信箱 -->
          <h4 class="chat-list-title">Agent</h4>
          <ul class="chat-users-list contact-list media-list" v-if="filteredContacts.length">
            <chat-contact
              v-for="contact in filteredContacts"
              :class="{ active: activeChatAgentId === contact.id }"
              :key="contact.id"
              :user="contact" tag="li"
              @click="$emit('open-chat', { contact, c: true })"
            />
          </ul>
          <ul v-else>
            <b-card style="margin: 20px auto" class="mb-2">
              <b-card-text> No more messages </b-card-text>
            </b-card>
          </ul>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed, watch, toRefs } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import store from '@/store'
import { expandTree } from '@/views/vip_web/LetterManager/chatStoreModule'
import ChatContact from './ChatContact.vue'
// import UserProfileSidebar from './UserProfileSidebar.vue'

export default {
  components: {
    BButton,
    // BSV
    // BInputGroup,
    // BInputGroupPrepend,
    // BFormInput,
    BCardText,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    // UserProfileSidebar,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: [String, Number],
      default: null,
    },
    activeChatAgentId: {
      type: [String, Number],
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = contact => contact.name || contact.real_name
    const filteredChatsContacts = computed(() => props.chatsContacts.filter(searchFilterFunction))
    const filteredContacts = computed(() => props.contacts.filter(searchFilterFunction))
    const load = ref(false)

    const confirmSearch = () => {
      load.value = true
      axiosIns
        .get('user/childrenUser2', {
          params: {
            name: searchQuery.value,
          },
        })
        .then(res => {
          store.commit('app-chat/SET_CONTACTS', expandTree(res.data.list || res.data))
          load.value = false
        })
    }
    watch(searchQuery, newVal => {
      if (!newVal) {
        confirmSearch()
      }
    })

    return {
      load,
      confirmSearch,
      // Search Query
      searchQuery,
      filteredChatsContacts,
      filteredContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    }
  },
}
</script>
