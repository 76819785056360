/** @format */

import axios from '@axios'

// 方法,展开树图, 字段为child
export const expandTree = (data, field = 'child') => {
  if (!data) return []
  let result = []
  data.forEach(item => {
    result.push(item)
    if (item[field] && item[field].length > 0) {
      result = result.concat(expandTree(item[field], field))
    }
  })
  return result
}

export default {
  namespaced: true,
  state: {
    chatsContacts: [], // 聊天联系人列表
    contacts: [], // 联系人列表
  },
  getters: {},
  mutations: {
    SET_CHATS_CONTACTS(state, data) {
      state.chatsContacts = data
    },
    SET_CONTACTS(state, data) {
      state.contacts = data
    },
  },
  actions: {
    getTopUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/getParentUserAndChildUser')
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getLeftUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/childrenUser2', {
            params: data,
          })
          .then(res => {
            resolve(expandTree(res.data?.list || res.data))
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/chats-and-contacts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/email/list?from_user_id=${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/email/add', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
