<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
      :src="user.avatar | avatarFil | avatarFilT"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mt-1">
        {{ user.name || user.real_name }}
      </h5>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  filters: {
    avatarFil(val) {
      if (val == process.env.VUE_APP_COM_URL) {
        return val.replace(process.env.VUE_APP_COM_URL, '')
      }
      return val
    },
    avatarFilT(val) {
      if (val == process.env.VUE_APP_COM_URLNS) {
        return val.replace(process.env.VUE_APP_COM_URL, '')
      }
      return val
    },
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return { formatDateToMonthShort, resolveAvatarBadgeVariant }
  },
}
</script>

<style>

</style>
