<!-- @format -->

<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: shallShowActiveChatContactSidebar || mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div v-if="!activeChat.contact" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="MessageSquareIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">Send Message</h4>
      </div>

      <!-- Chat Content -->
      <div v-else class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="mqShallShowLeftSidebar = true" />
              </div>

              <b-avatar
                size="36"
                :src="activeChat.contact.avatar"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.status)"
                @click.native="shallShowActiveChatContactSidebar = true"
              />
              <h6 class="mb-0">
                {{ activeChat.contact.fullName }}
              </h6>
              <div @click="openChatOfContact({ contact: callUserGetId })">
                <feather-icon icon="RotateCwIcon" class="ml-1" size="22" style="" />
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
          <chat-log :chat-data="activeChat" :profile-user-avatar="profileUserDataMinimal.avatar" :left-user-id="profileUserDataMinimal.id" :right-user-id="callUserGetId.id" />
        </vue-perfect-scrollbar>
        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input v-model="chatInputMessage" placeholder="Enter the message..." />

            <span
              class="input-group-text"
              style="
              border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    "
            >
              <label for="attach-doc" class="attachment-icon form-label mb-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image cursor-pointer text-secondary"><rect x="3" y="3" width="18" height="18" rx="2" ry="2" /><circle cx="8.5" cy="8.5" r="1.5" /><polyline points="21 15 16 10 5 21" /></svg>
                <input type="file" :value="chatImgMessage" id="attach-doc" hidden></label>
            </span>

          </b-input-group>
          <b-button variant="primary" type="submit"> Send </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar" :contact="activeChat.contact || {}" />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-agent-id="activeChatAgentId"
        :active-chat-contact-id="activeChatContactId"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted, nextTick, getCurrentInstance, computed } from '@vue/composition-api'
import { BAvatar, BForm, BInputGroup, BFormInput, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'

export default {
  components: {
    // BSV
    BAvatar,

    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  setup() {
    // eslint-disable-next-line
    const { proxy } = getCurrentInstance()
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)
    // onUnmounted(() => {
    //   if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    // })

    const { resolveAvatarBadgeVariant } = useChat()

    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = computed(() => store.state[CHAT_APP_STORE_MODULE_NAME].chatsContacts)
    const contacts = computed(() => store.state[CHAT_APP_STORE_MODULE_NAME].contacts)

    const fetchChatAndContacts = () => {
      store.dispatch('app-chat/getTopUser').then(response => {
        if (Array.isArray(response.parent_user)) {
          // chatsContacts.value = response.parent_user
          store.commit('app-chat/SET_CHATS_CONTACTS', response.parent_user)
        } else {
          store.commit('app-chat/SET_CHATS_CONTACTS', [response.parent_user])
          // chatsContacts.value = [response.parent_user]
        }
      })
      store.dispatch('app-chat/getLeftUser').then(response => {
        store.commit('app-chat/SET_CONTACTS', response)
        console.log(' rese', response)
      })
    }

    fetchChatAndContacts()

    const callUserGetId = ref(null)
    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const chatImgMessage = ref('')
    const activeChatAgentId = ref('')
    const activeChatContactId = ref('')
    const openChatOfContact = userId => {
      chatImgMessage.value = ''
      chatInputMessage.value = ''
      callUserGetId.value = userId.contact

      store.dispatch('app-chat/getChat', { userId: userId.contact.id }).then(response => {
        // eslint-disable-next-line no-use-before-define
        profileUserDataMinimal.value = response.data.userInfo
        const ddata = {
          chat: {
            id: new Date().getTime(),
            userId: response.data.userInfo.id,
            chat: (() => {
              const result = []
              response.data.data.forEach(item => result.push({
                message: item.content,
                time: item.created_at,
                senderId: item.from_user_id,
              }),)
              return result
            })(),
          },
          contact: {
            id: response.data.userInfo.id,
            fullName: userId.contact.real_name,
            avatar: userId.contact.avatar,
          },
        }
        activeChatContactId.value = userId.contact.id
        activeChatAgentId.value = userId.contact.id
        activeChat.value = ddata

        // 设置未读消息为已读
        // const contact = chatsContacts.value.find(c => c.id === userId)
        // // console.log(contact)

        // if (contact) contact.chat.unseenMsgs = 0

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const sendMessage = () => {
      const attach = document.getElementById('attach-doc')
      if (!chatInputMessage.value && !attach.value) return
      const payload = {
        // contactId: activeChat.value.contact.id,
        // senderId: profileUserDataMinimal.value.id,
        // eslint-disable-next-line no-use-before-define
        title: new Date().toLocaleString(),
        user_id: callUserGetId.value.id,
        content: chatInputMessage.value || attach.files[0],
      }

      store
        .dispatch('app-chat/sendMessage', payload)
        // eslint-disable-next-line
        .then(response => {
          const newMessageData = {
            // eslint-disable-next-line
            id: profileUserDataMinimal.value.id,
            message: chatInputMessage.value || chatImgMessage.value,
            // eslint-disable-next-line
            senderId: profileUserDataMinimal.value.id,
          }

          activeChat.value.chat.chat.push(newMessageData)
          chatInputMessage.value = ''
          chatImgMessage.value = ''

          nextTick(() => {
            scrollToBottomInChatLog()
          })
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const profileUserData = ref({})
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser').then(response => {
        profileUserData.value = response.data
        shallShowUserProfileSidebar.value = true
      })
    }

    const shallShowActiveChatContactSidebar = ref(false)

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      chatImgMessage,
      openChatOfContact,
      sendMessage,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      callUserGetId,
      activeChatAgentId,
      activeChatContactId
    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
</style>
